// import { Link, useLocation } from "react-router-dom";
import { useSelector } from "store";
import { selectStudent } from "store/reducers/user";

const Profile = () => {
    const student = useSelector(selectStudent);
    return (
        <ul class="header-menu nav d-flex align-items-center">
            <li>
                <div class="stud-o d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="s-img p-relative">
                            <img 
                                src={student?.profile_image ? student?.profile_image : (student?.StudentDetails?.gender === "Female" ? require("assets/images/female_profile.jpg") : require("assets/images/male_profile.jpg"))}   
                                class="me-2 rounded-circle"
                                width="40"
                                height="40"
                                onError={(e)=> {
                                    e.target.onerror = null;
                                    e.target.src = require('assets/images/male_profile.jpg');
                                }}
                            />
                            <span class="medal">
                                <img src={require('assets/images/student/gold-medal.png')} alt="Medal" width={18}/>
                            </span>
                        </div>
                        <div class="s-content me-4 mob-hide">
                            <h6 className="mb-0">{student?.first_name +" "+ student?.last_name}</h6>
                            <strong>{student?.StudentDetail?.Grade?.name}</strong>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
}

export default Profile;