import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserTaskDetailApi } from "apis/Student/AssignmentApis";
// import axios from "axios";
// import problems from "data/problems";

const fetchProblems = createAsyncThunk("problems/fetch", async (userTaskId) => {
  // const response = await axios.get(
  //   `https://opentdb.com/api.php?amount=${amount}`
  // );
  getUserTaskDetailApi({user_task_id: userTaskId}).then((res) => {
    if (res?.data?.success) {
      return res?.data?.data?.Exercise?.Questions.map((question, index) => ({
        ...question,
        id: index + 1,
        selectedAnswer: null,
        isVisited: false,
      }));
    }
  })
  .catch((err) => {
    console.log(err);
  });

  // return problems.map((question, index) => ({
  //   ...question,
  //   id: index + 1,
  //   selectedAnswer: null,
  //   isVisited: false,
  // }));
});

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

const problemsSlice = createSlice({
  name: "problems",
  initialState,
  reducers: {
    fetchQuestions: (state, action) => {
      state.data = action.payload;
    },
    markAsVisited: (state, action) => {
      const problem = state.data.find(
        (problem) => problem.id === action.payload
      );
      problem.isVisited = true;
    },
    setSelectedAnswer: (state, action) => {
      const problem = state.data.find(
        (problem) => problem.id === action.payload.id
      );
      problem.selectedAnswer = action.payload.selectedOption;
    },
    setSubmittedAnswer: (state, action) => {
      const problem = state.data.find(
        (problem) => problem.id === action.payload.id
      );
      problem.submittedAnswer = action?.payload?.inputAnswer;
    },
    setSubmittedMultipleInputAnswer: (state, action) => {
      const problem = state.data.find(
        (problem) => problem.id === action.payload.id
      );
      problem.submittedAnswerMultipleInput = action?.payload?.multipleInputAnswer;
    },
    resetQuiz: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProblems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProblems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchProblems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export { fetchProblems };

export const { markAsVisited, setSelectedAnswer, setSubmittedAnswer, setSubmittedMultipleInputAnswer, resetQuiz, fetchQuestions, getData } = problemsSlice.actions;
export default problemsSlice.reducer;