
import SectionTitle from './SectionTitle'
import Search from './Search'
import Profile from './Profile'
import Notification from './Notification'

const HeaderContent = () => {
    return (
        <div className="app-header__content">
            <SectionTitle />
            <div className="app-header-right">
                {/* <Search /> */}
                <Notification />
                <Profile />
            </div>
        </div>
    );
}

export default HeaderContent;