import { useLocation } from "react-router-dom";

const SectionTitle = () => {
    const location = useLocation().pathname;
    let title = "";

    if (location.includes("/parent/dashboard")) {
        title = "Dashboard";
    } else if (location.includes("/parent/account-profile")) {
        title = "Account Profile";
    } else if (location.includes("/parent/leaderboard")) {
        title = "Leaderboard";
    } else if (location.includes("/parent/progress-report")) {
        title = "Progress Report";
    } else if (location.includes("/parent/knowledge-base")) {
        title = "Knowledge Base";
    } else if (location.includes("/parent/support")) {
        title = "Support";
    } else if (location.includes("/parent/settings")) {
        title = "Settings";
    } else if (location.includes("/parent/billing")) {
        title = "Billing";
    } else if (location.includes("/parent/notifications")) {
        title = "Notifications";
    } else if(location.includes("/parent/subscription-plans")) {
        title = "Subscription Plans";
    } else if(location.includes("/parent/order-summary")) {
        title = "Order Summary";
    } else if(location.includes("/parent/manage-plan")) {
        title = "Manage Plan";
    } else if (location.includes("/parent/logout")) {
        title = "Logout";
    } else {
        title = "Dashboard";
    }

    return (
        <div className="app-header-left d-flex flex-column">
            <h1>{title}</h1>
            <p className="i-hide">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
            </p>
        </div>
    );
}

export default SectionTitle;