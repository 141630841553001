import { createSlice } from "@reduxjs/toolkit";
import { getProfileApi } from "apis/Parent/ProfileApis";
import { getStudentProfileApi } from "apis/Student/ProfileApis";
import {
    getUnreadNotificationsApi
  } from "apis/Parent/NotificationApis";
// import { dispatch } from "store";

const initialState = {
    user: null,
    isLoggedInAsStudent: false,
    student:null
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setStudent(state, action) {
            state.student = action.payload;
        },
        setIsLoggedInAsStudent(state, action) {
            state.isLoggedInAsStudent = action.payload;
        },
        emptyUser(state) {
            state.user = null;
        },
        emptyStudent(state) {
            state.student = null;
            state.isLoggedInAsStudent = false;
        },
        setNotifications(state, action) {
            state.notifications = action.payload;
        }
    }
});

export default user.reducer;


export const { setUser,setStudent, setIsLoggedInAsStudent,emptyStudent, emptyUser, setNotifications } = user.actions;

export const fetchParentProfile = () => async (dispatch) => {
    try {
        const response = await getProfileApi();
        const user = response.data.data ?? null;
        dispatch(setUser(user));
    } catch (error) {
        console.log("Failed to fetch parent user");
    }
}

export const fetchStudentProfile = () => async (dispatch) => {
    try {
        const response = await getStudentProfileApi();
        const user = response.data.data ?? null;
        dispatch(setStudent(user));
    } catch (error) {
        console.log("Failed to fetch student user");
    }
}

export const fetchNotifications = () => async (dispatch) => {
    try {
        const response = await getUnreadNotificationsApi({page: 1, limit: 3});
        const notifications = response?.data?.notifications ?? null;
        dispatch(setNotifications(notifications));
    } catch (error) {
        console.log("Failed to fetch Notifications");
    }
}

export const selectUser = (state) => state.user.user;
export const selectStudent = (state) => state.user.student;
export const selectIsLoggedInAsStudent = (state) => state.user.isLoggedInAsStudent;
export const selectNotifications = (state) => state.user.notifications;
