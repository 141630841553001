import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { lazy } from "react";
import { isValidToken } from "utils/token";

// project import
import ParentLayout from "layout/ParentLayout";
import Loadable from "components/Loadable";
import ParentAuthGuard from "guards/ParentAuthGuard";

const Dashboard = Loadable(lazy(() => import("pages/app-users/parent/dashboard/Dashboard")));
const AccountProfile = Loadable(lazy(() => import("pages/app-users/parent/account-profile/AccountProfile")));
const ProgressReport = Loadable(lazy(() => import("pages/app-users/parent/progress-report/ProgressReport")));
const KnowledgeBase = Loadable(lazy(() => import("pages/app-users/parent/knowledge-base/KnowledgeBase")));
const Leaderboard = Loadable(lazy(() => import("pages/app-users/parent/leaderboard/Leaderboard")));
const Support = Loadable(lazy(() => import("pages/app-users/parent/support/Support")));
const Settings = Loadable(lazy(() => import("pages/app-users/parent/settings/Settings")));
const Billing = Loadable(lazy(() => import("pages/app-users/parent/billing/Billing")));
const InvoiceDetails = Loadable(lazy(() => import("pages/app-users/parent/billing/invoice/details")));
const Notification = Loadable(lazy(() => import("pages/app-users/parent/notification/Notification")));
const Subscription = Loadable(lazy(() => import("pages/app-users/parent/subscription/Subscription")));
const ManageSubscription = Loadable(lazy(() => import("pages/app-users/parent/subscription/ManageSubscription")));
const OrderSummary = Loadable(lazy(() => import("pages/app-users/parent/subscription/OrderSummary")));
const PaymentStatus = Loadable(lazy(() => import("pages/app-users/parent/subscription/PaymentStatus")));
const Logout = Loadable(lazy(() => import("pages/app-users/parent/auth/Logout")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/parent",
  children: [
    {
      path: "/parent",
      element: (
        <ParentAuthGuard>
          <ParentLayout />
        </ParentAuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "account-profile",
          element: <AccountProfile />,
        },
        {
          path: "progress-report",
          element: <ProgressReport />,
        },
        {
          path: "leaderboard",
          element: <Leaderboard />,
        },
        {
          path: "knowledge-base",
          element: <KnowledgeBase />,
        },
        {
          path: "support",
          element: <Support />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "billing",
          element: <Billing />,
        },
        {
          path: "invoice-details/:invoiceId",
          element: <InvoiceDetails />,
        },
        {
          path: "notifications",
          element: <Notification />
        },
        {
          path: "subscription-plans/:studentId",
          element: <Subscription />
        },
        {
          path: "manage-plan/:studentId",
          element: <ManageSubscription />
        },
        {
          path: "order-summary/:studentId/:subscriptionId",
          element: <OrderSummary />
        },
        // {
        //   path: "payment-status/:status/:sessionId",
        //   element: <PaymentStatus />
        // },
        {
          path: "logout",
          element: <Logout />,
        },
      ],
    },
    {
      path: "payment-status/:status/:sessionId",
      element: <PaymentStatus />
    }
  ]
};

// eslint-disable-next-line no-unused-vars
const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/parent" && hasSession()) {
      navigate("/parent/dashboard");
    }

    if (location.pathname === "/parent" && !hasSession()) {
      navigate("/login");
    }

    if (location.pathname === "/parent/" && !hasSession()) {
      navigate("/login");
    }
  }, [location, navigate]);

  const hasSession = () => {
    const localKey = localStorage.getItem("accessToken");
    if (localKey && isValidToken(localKey)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <MainRoutes />
  );
};

// export default AppRoutes;
export default MainRoutes;