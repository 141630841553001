import axios from "axios";
import { APP_SECRET_KEY } from "../config/config";
import { publicIpv4 } from "public-ip";

import { getAccessToken, getStudentToken } from "utils/token";
const location = window.location.pathname;
const isStartWithStudent = location.startsWith("/student");

const postMethod = async (url, data) => {
  const axiosConfig = {
    url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-secret-key": `${APP_SECRET_KEY}`,
      "Authorization":`Bearer ${isStartWithStudent ? getStudentToken() : getAccessToken()}`,
      ip: await publicIpv4(),
    },
    data,
  };
  return axios(axiosConfig);
};

const getMethod = async (url, data) => {
  const axiosConfig = {
    url,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-secret-key": `${APP_SECRET_KEY}`,
      "Authorization":`Bearer ${isStartWithStudent ? getStudentToken() : getAccessToken()}`,
      ip: await publicIpv4(),
    },
    params: data,
  };

  return axios(axiosConfig);
};

const postFormDataMethod = async (url, data) => {
  const axiosConfig = {
    url,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "x-secret-key": `${APP_SECRET_KEY}`,
      "Authorization":`Bearer ${isStartWithStudent ? getStudentToken() : getAccessToken()}`,
      ip: await publicIpv4(),
    },
    data,
  };

  return axios(axiosConfig);
};

export { postMethod, getMethod, postFormDataMethod };
