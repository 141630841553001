import { lazy } from 'react';
import GuestGard from "../guards/GuestGuard";
import Loadable from 'components/Loadable';

const SignIn = Loadable(lazy(() => import("../pages/app-users/parent/auth/SignIn")));
const SignUp = Loadable(lazy(() => import("../pages/app-users/parent/auth/SignUp")));
const ForgotPassword = Loadable(lazy(() => import("../pages/app-users/parent/auth/ForgotPassword")));
const NotFound = Loadable(lazy(() => import("../pages/error/NotFound")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      children: [
        {
          path: "/",
          element: (
            <GuestGard>
              <SignIn />
            </GuestGard>
          ),
        },
        {
          path: "/login",
          element: (
            <GuestGard>
              <SignIn />
            </GuestGard>
          ),
        },
        {
          path: "/register",
          element: (
            <GuestGard>
              <SignUp />
            </GuestGard>
          ),
        },
        {
          path: "/404",
          element: <NotFound />,
        },
        // {
        //   path: "*",
        //   element: <Navigate to="/404" replace />,
        // },
      ],
    },
    {
      path: "forgot-password",
      element: (
        <ForgotPassword />
      ),
    }
  ]
};

export default LoginRoutes;
