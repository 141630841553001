import { createSlice } from "@reduxjs/toolkit";
import { getStudentsApi } from "apis/Parent/StudentApis";
import constants from "../../constants";
import {
    getUnreadNotificationsApi
  } from "apis/Student/NotificationApis";

const initialState = {
    activeStudent: null,
    studentList: [],
    loading: false,
    error: null,
};

const switchStudent = createSlice({
    name: "switchStudent",
    initialState,
    reducers: {
        setActiveStudent(state, action) {
            state.activeStudent = action.payload;
        },
        setStudentList(state, action) {
            state.studentList = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setStudentNotifications(state, action) {
            state.studentNotifications = action.payload;
        }
    },
});

export default switchStudent.reducer;


export const { setActiveStudent, setStudentList, setLoading, setError, setStudentNotifications } =
    switchStudent.actions;

export const selectActiveStudent = (state) => state.switchStudent.activeStudent;
export const selectStudentList = (state) => state.switchStudent.studentList;

export const fetchSwitchStudent = () => async (dispatch) => {
    try {
        const data = {
            page: 1,
            search: "",
            limit: constants.RECORDS_PER_PAGE.PER_PAGE,
        };
        dispatch(setLoading(true));
        const response = await getStudentsApi(data);
        dispatch(setStudentList(response?.data?.students?.rows));
        dispatch(setActiveStudent(response?.data?.students?.rows[0]));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setError(error));
        dispatch(setLoading(false));
    }
};

export const fetchNotifications = () => async (dispatch) => {
    try {
        const response = await getUnreadNotificationsApi({page: 1, limit: 3});
        const notifications = response?.data?.notifications ?? null;
        dispatch(setStudentNotifications(notifications));
    } catch (error) {
        console.log("Failed to fetch Notifications");
    }
}

export const selectStudentNotifications = (state) => state.switchStudent.studentNotifications;


