import { Outlet } from "react-router-dom";
import Header from "./header";
// import Breadcrumb from "./breadcrumb";
import { useMediaQuery } from "@mui/material";
import CanvasModel from "pages/app-users/student/canvas/CanvasModel";

// ==============================|| Exercise Layout ||============================== //

const ExerciseLayout = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <>
      <div className="assignment">
        <Header isMobile={isMobile} />
        {/* <Breadcrumb isMobile={isMobile} /> */}
        {/* <div className="assignment-outer">
          <div className="container-fluid px-5"> */}
            <Outlet />
          {/* </div>
        </div> */}
      </div>
      <CanvasModel />
    </>
  );
};

export default ExerciseLayout;
