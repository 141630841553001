import { useEffect, useState } from "react";

// project import
import Routes from "routes";

import Loader from "components/Loader";
import Locales from "components/Locales";
import Notistack from "components/third-party/Notistack";
import CacheBuster from "react-cache-buster";

import { dispatch, useSelector } from "store";
import { fetchMenu } from "store/reducers/menu";
import { fetchParentProfile, fetchStudentProfile } from "store/reducers/user";
import { isValidToken } from "utils/token";
import { selectActiveStudent } from "store/reducers/student";
import { fetchSwitchStudent } from "store/reducers/student";
import NetworkGuard from "guards/NetworkGuard";

// auth provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loading, setLoading] = useState(true);
  const localKey = localStorage.getItem("accessToken");
  const studentLocalKey = localStorage.getItem("studentToken");
  const activeStudent = useSelector(selectActiveStudent);
  const { version } = require("../package.json");

  useEffect(() => {
    // for production
    console.error = () => {};
    // fetch dashboard menu from API
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });

    if (localKey && isValidToken(localKey)) {
      dispatch(fetchParentProfile());
      if (!activeStudent) {
        dispatch(fetchSwitchStudent());
      }
    }

    if (studentLocalKey && isValidToken(studentLocalKey)) {
      dispatch(fetchStudentProfile());
    }
  }, [localKey, studentLocalKey, activeStudent]);

  if (loading) return <Loader />;

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<Loader />}
      metaFileDirectory={"."}
    >
      <NetworkGuard>
        <Locales>
          <AuthProvider>
            <>
              <Notistack>
                <Routes />
              </Notistack>
            </>
          </AuthProvider>
        </Locales>
      </NetworkGuard>
    </CacheBuster>
  );
};

export default App;
