import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchNotifications } from "store/reducers/user";
import { selectNotifications } from "store/reducers/user";
import { selectUser } from "store/reducers/user";
import { useSelector } from "store";
import { dispatch } from "store";
import { updateNotificationApi } from "apis/Parent/NotificationApis";

const NewNotification = () => {
  const parent = useSelector(selectUser);
  const [payLoad, setPayLoad] = useState({
    page: 1,
    limit: 3,
  });
  const notifications = useSelector(selectNotifications);
  const count = notifications?.count > 0 ? notifications?.count : null;
  const getNotifications = () => {
    dispatch(fetchNotifications());
  };

  useEffect(() => {
    getNotifications(payLoad);
  }, []);

  const convertTimeElapsed = (time_elapsed) => {
    const [days, hours, minutes] = time_elapsed.split(":");
    if (days === "00") {
      if (hours === "00") {
        return `${minutes} minutes ago`;
      } else {
        return `${hours} hours ago`;
      }
    } else {
      return `${days} days ago`;
    }
  };

  const readNotification = () => {
    updateNotificationApi({ clearAll: "Yes" })
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchNotifications());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ul className="header-menu nav d-flex align-items-center">
      <li className="nav-item">
        <div className={`nav-link notify`}>
          <img
            src={require("assets/images/wallet.png")}
            width="15"
            className="me-1"
          />
            <span className="count p-1">{parent?.totalRewardPoints ? parseInt(parent?.totalRewardPoints) : 0}</span>
        </div>
      </li>
      <li className="nav-item">
        {notifications?.count === 0 && (
          <>
            <Link to="/parent/notifications">
              <a href="#" className={`nav-link notify`}>
                <img
                  src={require("assets/images/icon_bell.png")}
                  width="15"
                  className="me-1"
                />
              </a>
            </Link>
          </>
        )}

        {notifications?.count > 0 && (
          <>
            <a
              href="#"
              className="nav-link notify"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={require("assets/images/icon_bell.png")} width={15} />
              <span className="count p-1">{count}</span>
            </a>
            <div
              tabIndex={-1}
              role="menu"
              aria-hidden="true"
              className="dropdown-menu dropdown-menu-right notify-dropdown"
            >
              <h5 className="dropdown-item d-flex">
                Notifications &nbsp;
                <a
                  href=""
                  className="nav-link notify d-flex align-items-center"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={require("assets/images/icon_bell.png")}
                    width={10}
                    className="mr-5"
                  />{" "}
               {count}
                </a>
              </h5>
              <div tabIndex={-1} className="dropdown-divider" />
              <div
                className={`overflow-scroll s-notification-list ${
                  notifications?.rows.length >= 3 ? "with-fixed-height" : ""
                }`}
              >
                {notifications?.rows?.map((notification) => (
                  <>
                    <Link to="/parent/notifications">
                      <div className="d-flex flex-row dropdown-item d-flex justify-content-start n-item">
                        <div>
                          {notification?.status === 0 ? (
                            <div className="circle" />
                          ) : (
                            <div className="" />
                          )}
                        </div>
                        <div className="ml-10">
                          <img
                            src={notification?.NotificationType?.icon_url}
                            width={24}
                            height=""
                            alt=""
                            onError={(e) => {
                              e.target.src = require("assets/images/student/alarm_on.png")
                            }}
                          />
                        </div>
                        <div className="ml-10">
                          <h5 className="mb-1">
                            {notification?.title}{" "}
                            <span>
                              {convertTimeElapsed(notification?.time_elapsed)}
                            </span>
                          </h5>
                          <p className="mb-2 notification-message">{notification?.message}</p>
                        </div>
                      </div>
                      <div tabIndex={-1} className="dropdown-divider my-3" />
                    </Link>
                  </>
                ))}
              </div>
              <div tabIndex={-1} className="dropdown-divider my-3" />
              <ul className="d-flex justify-content-end me-2 align-items-center">
                <li>
                  <a
                    href="#"
                    tabIndex={0}
                    className="dropdown-item text-center clear-text"
                    onClick={readNotification}
                  >
                    Clear All
                  </a>
                </li>
                <li>
                  <Link
                    to="/parent/notifications"
                    className="dropdown-item text-center btn-blue"
                  >
                    View All
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )}
      </li>
    </ul>
  );
};

export default NewNotification;
