import { Outlet } from "react-router-dom";
import Drawer from "./Drawer";
import Header from "./Header";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
// import Footer from './Footer';
// import HorizontalBar from './Drawer/HorizontalBar';
import { useSelector, useDispatch } from "store";
import VideoModel from "pages/app-users/student/knowledge-base/VideoModel";
import { setSidebarToggle, setMobileView, setSidebarValue } from "store/reducers/student-layout";

// ==============================|| MAIN LAYOUT ||============================== //

const StudentLayout = () => {
  const isSidebarOpen = useSelector((state) => state.studentLayout.isSidebarOpen);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    dispatch(setMobileView(true));
    setOpen(!open);
  };

  const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <>
      <div
        className={`app-container fixed-sidebar fixed-header 
        ${
          isSidebarOpen ? "closed-sidebar" : ""
        } ${isMobile && !isSidebarOpen ? "closed-sidebar-mobile" : ""}`}
      >
        <Header isOpen={open} toggleMenu={toggleMenu} isMobile={isMobile} />
        <div className="app-main">
          <Drawer />
          <div class="app-main__outer">
            <div className="app-main__inner">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <VideoModel />
    </>
  );
};

export default StudentLayout;
