import { postMethod, postFormDataMethod } from "../apiConfig";
import { API_URL } from "../../config/config";
import * as url from "../../helpers/parent_url_helper";

export const getProfileApi = (data) => {
  return postMethod(`${API_URL}${url.GET_PROFILE_DETAILS}`, data);
};

export const updateProfileApi = (data) => {
    return postFormDataMethod(`${API_URL}${url.UPDATE_PROFILE_DETAILS}`, data);
};

export const updatePasswordApi = (data) => {
    return postMethod(`${API_URL}${url.UPDATE_PASSWORD}`, data);
}

export const updateProfileImage = (data) => {
    return postFormDataMethod(`${API_URL}${url.UPDATE_PROFILE_IMAGE}`, data);
}
