import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useAuthentication = () => {
    const context = useContext(AuthContext);

    if (!context) throw new Error('Authentication context must be use inside AuthProvider');
  
    return context;
  };

export default useAuthentication;