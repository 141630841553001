import React from "react";
import { Excalidraw } from "@excalidraw/excalidraw";

const CanvasModel = () => {
  const handleOnClose = () => {
  
  };
  return (
    <div class="modal modal-xl fade" id="canvasModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle">Color Pencil Whiteboard</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              onClick={()=> handleOnClose()}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div style={{ height: "500px" }}>
              <Excalidraw
                initialData={{
                  appState: { zenModeEnabled: true, viewBackgroundColor: "#FFFFE0" },
                  scrollToContent: true
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasModel;