// import { useEffect } from 'react';

import Navigation from './Navigation';
// import DrawerHeader from './DrawerHeader';

const DrawerContent = () => {
    return (
        <>
            {/* <DrawerHeader /> */}
            <Navigation />
        </>
    );
}

export default DrawerContent;