import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuthentication from 'hooks/useAuthentication';

// components
import LoadingScreen from 'components/LoadingScreen';

import { STUDENT_DASHBOARD_PATH } from 'config/config';

// ----------------------------------------------------------------------

StudentAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function StudentAuthGuard({ children }) {
  const { isStudentAuthenticated, isInitialized } = useAuthentication();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isStudentAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={STUDENT_DASHBOARD_PATH} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
