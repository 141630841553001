import { postMethod, postFormDataMethod } from "apis/apiConfig";
import { API_URL } from "config/config";
import * as url from "../../helpers/parent_url_helper";

export const getStudentsApi = (data) => {
  return postMethod(`${API_URL}${url.GET_STUDENTS}`, data);
};

export const getSwitchStudentsApi = (data) => {
  return postMethod(`${API_URL}${url.GET_SWITCH_STUDENTS}`, data);
};

export const getStudentApi = (data) => {
  return postMethod(`${API_URL}${url.GET_STUDENT}`, data);
};

export const createStudentApi = (data) => {
  return postFormDataMethod(`${API_URL}${url.CREATE_STUDENT}`, data);
};

export const updateStudentApi = (data) => {
    return postFormDataMethod(`${API_URL}${url.UPDATE_STUDENT}`, data);
};

export const deleteStudentApi = (data) => {
  return postMethod(`${API_URL}${url.DELETE_STUDENT}`, data);
};

export const updateStudentSignInApi = (data) => {
  return postMethod(`${API_URL}${url.UPDATE_STUDENT_SIGN_IN}`, data);
};