import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import useAuthentication from '../hooks/useAuthentication';
import { PARENT_DASHBOARD_PATH } from '../config/config';

ParentGuestGuard.propTypes = {
    children: PropTypes.node.isRequired
};

function ParentGuestGuard ({ children }) {
    const { isAuthenticated } = useAuthentication();

    if (isAuthenticated) {
      return <Navigate to={PARENT_DASHBOARD_PATH} replace />;
    }
  
    return <>{children}</>;
}

export default ParentGuestGuard;