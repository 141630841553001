// import { useEffect } from 'react';
import { Outlet } from "react-router-dom";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "store";
import { setSidebarToggle, setMobileView, setSidebarValue } from "store/reducers/parent-layout";

//modals
// import ChangePassword from "pages/apps/parent/dialogs/ChangePassword";
import SwitchStudent from "pages/apps/parent/dialogs/SwitchStudent";
import VideoModel from "pages/app-users/parent/knowledge-base/VideoModel";

// ==============================|| MAIN LAYOUT ||============================== //

const ParentLayout = () => {
  const isSidebarOpen = useSelector((state) => state.parentLayout.isSidebarOpen);
  const dispatch = useDispatch();
  

  const isMobile = useMediaQuery("(max-width:767px)");
  if(isMobile){
    dispatch(setMobileView(true));
  }

  return (
    <>
      <div
        className={`app-container fixed-sidebar fixed-header ${
          isSidebarOpen ? "closed-sidebar" : ""
        } ${isMobile && !isSidebarOpen ? "closed-sidebar-mobile" : ""}`}
      >
        <Header />
        <div className="app-main">
          <Drawer />
          <div class="app-main__outer">
            <div className="app-main__inner">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {/* <SwitchStudent /> */}
      {/* <ChangePassword /> */}
      <VideoModel />
    </>
  );
};

export default ParentLayout;
