import { useEffect, useState } from "react";
import { useSelector } from "store";
import { selectActiveStudent } from "store/reducers/student";
import { getSwitchStudentsApi } from "apis/Parent/StudentApis";

// material-ui
import { Dialog } from "@mui/material";
import { PopupTransition } from "components/@extended/Transitions";
// import { selectUser } from "store/reducers/user";

import SwitchStudent from "pages/apps/parent/dialogs/SwitchStudent";

// Import Images
const maleProfileImg = require("assets/images/male_profile.jpg");
const femaleProfileImg = require("assets/images/female_profile.jpg");
const rightArrowImg = require("assets/images/right-arrow.png");

const DrawerHeader = () => {
  const activeStudent = useSelector(selectActiveStudent);
  const [showSwitchStudent, setShowSwitchStudent] = useState(false);
  const [students, setStudents] = useState(null);

  const handleShowSwitchStudent = () => {
    setShowSwitchStudent(!showSwitchStudent);
  };

  const getStudents = (data) => {
    getSwitchStudentsApi(data)
      .then((res) => {
        if (res.data.success) {
          setStudents(res?.data?.data);
        } else {
          setStudents([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (activeStudent) {
      getStudents({
        current_user_id: activeStudent?.StudentDetails?.id,
      });
    }
  }, [activeStudent]);

  // const parent = useSelector(selectUser);
  // let userName = activeStudent ? activeStudent?.StudentDetails?.first_name+" "+activeStudent?.StudentDetails?.last_name : parent?.first_name+" "+parent?.last_name;
  // let userProfileImage = activeStudent ? activeStudent?.StudentDetails?.profile_image : parent?.profile_image;

  return (
    <>
      <li>
        {activeStudent && (
          <div className="stud-o d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <div
                className="me-2 s-img"
                style={{ width: "40px", height: "40px" }}
              >
                <img
                  src={
                    activeStudent?.StudentDetails?.profile_image
                      ? activeStudent?.StudentDetails?.profile_image
                      : (activeStudent?.StudentDetails?.gender === "Female" ? femaleProfileImg : maleProfileImg )
                  }
                  alt="student"
                  className="rounded-circle"
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = maleProfileImg;
                  }}
                />
                <span className="online" />
              </div>
              <div className="s-content me-2">
                <h6 className="mb-0">
                  {activeStudent?.StudentDetails?.first_name }
                   
                </h6>
                {/* {(!activeStudent) && (
                                    <strong>{parent?.email}</strong>
                                )} */}
                {activeStudent && (
                  <>
                    <strong className="d-block">
                      {activeStudent?.Grade?.name}
                    </strong>
                    {/* {activeStudent?.StudentDetails?.status === "Approved" ? (
                        <strong className="text-success">Active</strong>
                      ) : (
                        <strong className="text-danger">Inactive</strong>
                      )} */}
                  </>
                )}
              </div>
            </div>
            {/* {activeStudent && (
                            <div className="ar-hide">
                                <img
                                    width={10}
                                    src={rightArrowImg}
                                    alt="right-arrow"
                                />
                            </div>
                        )} */}
            <a href="#" onClick={handleShowSwitchStudent}>
              {activeStudent && students?.length > 0 && (
                <div class="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    disabled
                  />
                </div>
              )}
            </a>
          </div>
        )}
      </li>
      <Dialog
        maxWidth="sm"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={handleShowSwitchStudent}
        open={showSwitchStudent}
        sx={{ "& .MuiDialog-paper": { p: 0 } }}
      >
        <SwitchStudent students={students} onCancel={handleShowSwitchStudent} />
      </Dialog>
    </>
  );
};

export default DrawerHeader;
