import { Link, useLocation } from "react-router-dom";
import { emptyUser } from "store/reducers/user";
import useAuthentication from "hooks/useAuthentication";
import { setSidebarToggle } from "store/reducers/parent-layout";
import { useDispatch } from "store";

const Navigation = () => {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const isParent = location.startsWith("/parent");
  const { signOut } = useAuthentication();

  const handleLogout = async () => {
    try {
      await signOut();
      dispatch(emptyUser());
      window.location.href = "/login";
    } catch (err) {
      console.error(err);
    }
  };

  const closeTheSidebar = () => {
    dispatch(setSidebarToggle());
  };

  const menuItems = [
    {
      name: "Dashboard",
      icon: require("assets/images/parent/dashboard2.png"),
      link: "/parent/dashboard",
      identifer: "dashboard",
    },
    {
      name: "Account Profile",
      icon: require("assets/images/parent/account-profile2.png"),
      link: "/parent/account-profile",
      identifer: "account-profile",
    },
    {
      name: "Leaderboard",
      icon: require("assets/images/parent/leaderboard2.png"),
      link: "/parent/leaderboard",
      identifer: "leaderboard",
    },
    {
      name: "Progress Report",
      icon: require("assets/images/parent/progress-report2.png"),
      link: "/parent/progress-report",
      identifer: "progress-report",
    },
    {
      name: "Knowledge Base",
      icon: require("assets/images/parent/knowledge-base2.png"),
      link: "/parent/knowledge-base",
      identifer: "knowledge-base",
    },
    {
      name: "Support",
      icon: require("assets/images/parent/support2.png"),
      link: "/parent/support",
      identifer: "support",
    },
    {
      name: "Billing",
      icon: require("assets/images/parent/billing2.png"),
      link: "/parent/billing",
      identifer: "billing",
    },
    {
      name: "Logout",
      icon: require("assets/images/parent/logout2.png"),
      identifer: "logout",
      onClick: handleLogout,
    },
  ];

  return (
    <>
      {/* <li className="app-sidebar__heading">Menu</li> */}
      {menuItems &&
        menuItems?.length > 0 &&
        menuItems?.map((item, index) => (
          <li
            key={index + 1}
            // onClick={index !== 8 ? closeTheSidebar : null}
          >
            <Link
              to={item?.link}
              onClick={item?.onClick}
              className={`${
                isParent && location.includes(item?.identifer)
                  ? "mm-active"
                  : ""
              }`}
            >
              <img src={item.icon} className="m-icon" alt="icon" width={18} />
              <span> {item.name} </span>
            </Link>
          </li>
        ))}
    </>
  );
};

export default Navigation;
