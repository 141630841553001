import React from "react";
import PropTypes from "prop-types";

const Loader = ({ isLoading }) => {
  return (
    isLoading && (
      <div class="loader-container">
        <img src={require("assets/gif/loader.gif")} alt="loader" style={{ width: "100px", height: "100px"}}/>
      </div>
    )
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
}

export default Loader;
