import React from "react";
import PropTypes from "prop-types";
import {setActiveStudent} from "store/reducers/student";
import {useDispatch} from "store";

const SwitchStudent = ({students, onCancel}) => {
  const dispatch = useDispatch();

  const handleSelectStudent = (student) => {
    dispatch(setActiveStudent(student));
    onCancel();
  };

  return (
    <>
    <div className="container">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title p-3">Switch Student</h4>
          <button
            type="button"
            className="btn-close"
            onClick={onCancel}
          />
        </div>
        <hr />
        <div className="modal-body">
          <ul className="list-group">
            {students && students?.length > 0 &&
              students?.map((student, index) => (
                <li className="list-group-item mb-1" style={{border: "none"}} key={index + 1}>
                  {/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */}
                  <a href="#" onClick={() => handleSelectStudent(student)}>
                    <div className="stud-o d-flex align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <div className="s-img">
                          <img
                            src={
                              student?.StudentDetails?.profile_image
                                ? student?.StudentDetails?.profile_image
                                : (student?.StudentDetails?.gender === "Female" ? require("assets/images/female_profile.jpg") : require("assets/images/male_profile.jpg"))
                            }
                            className="me-2 rounded-circle"
                            alt="student"
                            style={{
                              height: "45px",
                              width: "45px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = require("assets/images/male_profile.jpg");
                            }}
                          />
                        </div>
                        <div className="s-content mx-3">
                          <h6 className="mb-0">
                            {student?.StudentDetails?.first_name + " " + student?.StudentDetails?.last_name}
                          </h6>
                          <strong>{student?.Grade?.name}</strong>
                        </div>
                      </div>
                      <div>
                        <a
                          href="javascript:void(0);"
                          onClick={() => handleSelectStudent(student)}
                        >
                          <img
                            width={10}
                            src={require("assets/images/right-arrow.png")}
                            alt="right-arrow"
                          />
                        </a>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
              {
                students?.length === 0 && <p className="text-muted">No other students added</p>
              }
          </ul>
        </div>
      </div>
    </div>
  </>
  
  );
};

SwitchStudent.propTypes = {
  students: PropTypes.array,
  handleSelectStudent: PropTypes.func,
}

export default SwitchStudent;
