module.exports = {
    ROLE: {
        SUPERADMIN: 1,
        ADMIN : 2,
        PARENT : 3,
        STUDENT : 4
    },
    LOGIN_TYPES : {
        NOR : "NOR",
        GGL : "GGL",
        APL : "APL",
        FCB : "FCB"
    },
    RECORDS_PER_PAGE : {
        PER_PAGE : 10,
        STUDENT_CARD : 10
    },
    GENDER : {
        Male : "Male",
        Female : "Female",
        Other : "Other"
    },
    STATUS: {
        Active  : "Active",
        Inactive : "Inactive",
    },
    USER_TASK_STATUS: {
        Pending  : "Pending",
        Completed : "Completed",
    },
    USER_TASK_TYPE: {
        Exercise  : "Exercise",
        Learning : "Learning",
    },
    USER_ADDRESS_TYPE: {
        BILLING  : "Billing",
        SHIPPING : "Shipping",
    }
};