import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuthentication from 'hooks/useAuthentication';
// pages
import SignIn from 'pages/app-users/parent/auth/SignIn';
// components
import LoadingScreen from 'components/LoadingScreen';

// ----------------------------------------------------------------------

ParentAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function ParentAuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthentication();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <SignIn />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    console.log("requestedLocation", requestedLocation);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
