import React from "react";
import { useSelector } from "store";
import { selectStudent } from "store/reducers/user";

const Header = ({ isMobile }) => {
    const student = useSelector(selectStudent);
    return (
        <nav className="navbar assignment-header">
            <div className="container-fluid px-5">
                <div className="logo">
                    <img src={require("assets/images/logo.png")} alt="logo" width={115} />
                </div>
                <div className="stud-o d-flex align-items-center">
                    <div className="s-img p-relative">
                        <img
                            src={student?.profile_image ? student?.profile_image : (student?.StudentDetails?.gender === "Female" ? require("assets/images/female_profile.jpg") : require("assets/images/male_profile.jpg"))}   
                            className="me-2 rounded-circle"
                            alt="profile"
                            width={40}
                            height={40}
                            onError={(e)=> {
                                e.target.onerror = null;
                                e.target.src = require('assets/images/male_profile.jpg');
                            }}
                        />
                    </div>
                    <div className="s-content me-4">
                        <h6 className="mb-0">{student?.first_name +" "+ student?.last_name}</h6>
                        <strong>{student?.StudentDetail?.Grade?.name}</strong>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;