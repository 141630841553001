// Auth
export const SIGN_IN = "api/user/sign-in"
export const SIGN_UP = "api/user/sign-up"
export const FORGOT_PASSWORD = "api/user/forgot-password"
export const SEND_OTP = "api/user/send-otp"
export const CHECK_OTP = "api/user/check-otp"
export const RESET_PASSWORD = "api/user/reset-password"

// Knowledgebase
export const GET_FAQS = "api/user/get-faqs"
export const GET_HELP_VIDEOS = "api/user/get-videos"
export const GET_HELP_VIDEO = "api/user/get-video"

// Leaderboard
export const GET_STUDENT_LEADERBOARD = "api/user/get-student-leader-board"
export const GET_LEADERBOARD = "api/user/get-leader-board"

// Transaction
export const GET_PAYMENT_HISTORIES = "api/user/get-payment-histories"
export const GET_PAYMENT_HISTORY = "api/user/get-payment-history"

// Profile
export const GET_PROFILE_DETAILS = "api/user/get-profile-details"
export const UPDATE_PROFILE_DETAILS = "api/user/update-profile-detail"
export const UPDATE_PASSWORD = "api/user/update-password"
export const UPDATE_PROFILE_IMAGE = "api/user/update-profile-image"

// Progress Report
export const GET_STUDENT_CURRENT_WEEK_PROGRESS = "api/user/get-current-week-progress"
export const GET_STUDENT_CURRENT_WEEK_GRAPH_PROGRESS = "api/user/get-weekly-progress"
export const GET_PROGRESS_REPORT = "api/user/get-progress-report"
export const GET_USER_TASK_DETAILS = "api/user/get-user-task-detail"

// Support
export const GET_SUPPORTS = "api/user/get-supports"
export const CREATE_SUPPORT = "api/user/create-support"

// Students
export const CREATE_STUDENT = "api/user/create-student"
export const UPDATE_STUDENT = "api/user/update-student"
export const DELETE_STUDENT = "api/user/delete-student"
export const UPDATE_STUDENT_SIGN_IN = "api/user/update-sign-in"
export const GET_STUDENTS = "api/user/get-students"
export const GET_SWITCH_STUDENTS = "api/user/get-switch-students"
export const GET_STUDENT = "api/user/get-student"

// User address
export const GET_USER_ADDRESSES = "api/user/get-user-addresses"
export const GET_USER_ADDRESS = "api/user/get-user-address"
export const GET_USER_ADDRESS_BY_TYPE = "api/user/get-user-address-by-type"
export const CREATE_USER_ADDRESS = "api/user/create-user-address"

// Notifications
export const GET_NOTIFICATIONS = "api/user/get-notifications"
export const GET_UNREAD_NOTIFICATIONS = "api/user/get-unread-notifications"
export const UPDATE_NOTIFICATION = "api/user/update-notification"

// Subsciption
export const GET_SUBSCRIPTION_DURATIONS = "api/user/get-subscription-durations"
export const GET_SUBSCRIPTIONS = "api/user/get-subscriptions"
export const GET_SUBSCRIPTION = "api/user/get-subscription"
export const CANCLE_SUBSCRIPTION = "api/user/cancle-subscription"
export const APPLY_DISCOUNT_COUPON = "api/user/apply-discount-coupon"
export const CREATE_ORDER = "api/user/create-order"
export const CREATE_RZP_CUSTOMER = "api/user/create-rzp-customer"
export const CREATE_STRIPE_CUSTOMER = "api/user/create-stripe-customer"
export const CREATE_STRIPE_SESSION = "api/user/create-stripe-session"
export const RETRIEVE_STRIPE_SESSION = "api/user/retrieve-stripe-session"
export const CREATE_RZP_CUSTOMER_SUBSCRIPTION = "api/user/create-rzp-customer-subscription"
export const CREATE_STRIPE_CUSTOMER_SUBSCRIPTION = "api/user/create-stripe-customer-subscription"
export const UPDATE_ORDER = "api/user/update-order"
export const FAILED_ORDER = "api/user/failed-order"