import { postMethod } from "../apiConfig";
import { API_URL } from "../../config/config";
import * as url from "../../helpers/url_helper";

export const signInApi = (data) => {
  return postMethod(`${API_URL}${url.SIGN_IN}`, data);
};


export const signUpApi = (data) => {
  return postMethod(`${API_URL}${url.SIGN_UP}`, data);
};

export const forgetPasswordApi = (data) => {
  return postMethod(`${API_URL}${url.FORGOT_PASSWORD}`, data);
}

export const sendOTPApi = (data) => {
  return postMethod(`${API_URL}${url.SEND_OTP}`, data);
}

export const checkOTPApi = (data) => {
  return postMethod(`${API_URL}${url.CHECK_OTP}`, data);
}

export const resetPasswordApi = (data) => {
  return postMethod(`${API_URL}${url.RESET_PASSWORD}`, data);
}