import { useLocation } from "react-router-dom";
import { useSelector } from "store";
import { selectStudent } from "store/reducers/user";

const SectionTitle = () => {
    const location = useLocation().pathname;
    const student = useSelector(selectStudent);

    let title = "";
    let subtitle = "";
    if (location.includes("/student/dashboard")) {
        title = "Welcome Back, " + student?.first_name + "!";
        subtitle = "Finish your goals to build a better future.";
    } else if(location.includes("/student/leaderboard")){
        title = "Leaderboard";
        subtitle = "\"Believe you can and you're halfway there.\" - Theodore Roosevelt"
    } else if (location.includes("/student/support")) {
        title = "Support";
        subtitle = "Streamlining your support journey with concise and effective solutions at every step.";
    } else if(location.includes("/student/notifications")){
        title = "Notifications";
        subtitle = "Stay Informed: Your Gateway to Excitement!";
    } else if (location.includes("/student/account-profile")) {
        title = "Account Profile";
        subtitle = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
    } else if (location.includes("/student/progress-report")) {
        title = "Progress Report";
        subtitle = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
    } else if (location.includes("/student/knowledge-base")) {
        title = "Knowledge Base";
        subtitle = "Answers to Common Questions and Helpful Videos.";
    } else if (location.includes("/student/learning")) {
        title = "Learning";
        subtitle = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
    } else {
        title = "Welcome Back, " + student?.first_name + "!";
    }

    return (
        <div className="app-header-left d-flex flex-column">
            <h1 className="welcome-text">{title}</h1>
            <p className="mb-0 mob-hide">{subtitle}</p>
        </div>
    );
}

export default SectionTitle;