import { createContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { isValidToken, setSession } from "../utils/token";
import { signInApi, signUpApi } from "apis/Parent/AuthApis";
import { studentSignInApi, signInAsStudentApi } from "apis/Student/AuthApis";
import { getProfileApi } from "apis/Parent/ProfileApis";
import { getStudentProfileApi } from "apis/Student/ProfileApis";
import constants from "../constants";
// import {login, register, logout} from 'store/reducers/auth'
// import { useDispatch } from "store";

const initialState = {
  isAuthenticated: false,
  isStudentAuthenticated: false,
  isInitialized: false,
  user: null,
  student: null,
  isLoggedInAsStudent: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isStudentAuthenticated, user, StudentDetail } =
      action.payload;
    return {
      ...state,
      isAuthenticated,
      isStudentAuthenticated,
      isInitialized: true,
      user,
      student: StudentDetail,
    };
  },
  SIGNIN: (state, action) => {
    const { user, StudentDetail } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      student: StudentDetail,
      isInitialized: true,
      isStudentAuthenticated: StudentDetail ? true : false,
    };
  },
  STUDENTSIGNIN: (state, action) => {
    const { user, StudentDetail } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      student: StudentDetail,
      isInitialized: true,
      isStudentAuthenticated: StudentDetail ? true : false,
    };
  },
  SIGNINASSTUDENT: (state, action) => {
    const { user, StudentDetail } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      student: StudentDetail,
      isInitialized: true,
      isStudentAuthenticated: StudentDetail ? true : false,
    };
  },
  SIGNOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    student: null,
    isInitialized: true,
    isStudentAuthenticated: false,
  }),
  SIGNUP: (state, action) => {
    const { user, StudentDetail } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      student: StudentDetail,
      isInitialized: true,
      isStudentAuthenticated: StudentDetail ? true : false,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  studentSignIn: () => Promise.resolve(),
  signInAsStudent: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = window.location.pathname;
  const isStartWithParent = location.startsWith("/parent");
  const isStartWithStudent = location.startsWith("/student");
  // const dispatchState = useDispatch();

  const signUp = async (payload) => {
    const response = await signUpApi(payload);
    const { token, data } = response?.data;
    if (!data || !token) {
      const customError = (message = "") => {
        const error = new Error();
        error.name = "CustomError";
        error.message = message;
        throw error;
      };
      customError(response?.data?.message);
    }
    setSession(token);
    dispatch({
      type: "SIGNUP",
      payload: {
        data,
      },
    });

    // dispatchState(register({user: data}))

    return response?.data;
  };

  const signIn = async (payload, type) => {
    const response = await signInApi(payload);
    const { token, data } = response?.data;
    if (!data || !token) {
      const customError = (message = "") => {
        const error = new Error();
        error.name = "CustomError";
        error.message = message;
        error.redirectTo = response?.data?.redirectTo;
        throw error;
      };
      customError(response?.data?.message);
    }
    if (data?.Role?.id === constants.ROLE.STUDENT) {
      setSession(token, "STUDENT");
    } else if (data?.Role?.id === constants.ROLE.PARENT) {
      setSession(token, "PARENT");
    } else {
      setSession(token, "PARENT");
    }
    dispatch({
      type: "SIGNIN",
      payload: {
        data,
      },
    });
    // dispatchState(login({ user: data}));
  };

  const signInAsStudent = async (payload, type) => {
    const response = await signInAsStudentApi(payload);
    const { token, data } = response?.data;
    if (!data || !token) {
      const customError = (message = "") => {
        const error = new Error();
        error.name = "CustomError";
        error.message = message;
        error.redirectTo = response?.data?.redirectTo;
        throw error;
      };
      customError(response?.data?.message);
    }

    if (data?.Role?.id === constants.ROLE.STUDENT) {
      setSession(token, "STUDENT");
    } else {
      setSession(null)
    }
    dispatch({
      type: "STUDENTSIGNIN",
      payload: {
        data,
      },
    });
    // dispatchState(login({ user: data}));
  };

  const studentSignIn = async (payload, type) => {
    const response = await studentSignInApi(payload);
    const { token, data } = response?.data;
    if (!data || !token) {
      const customError = (message = "") => {
        const error = new Error();
        error.name = "CustomError";
        error.message = message;
        error.redirectTo = response?.data?.redirectTo;
        throw error;
      };
      customError(response?.data?.message);
    }

    if (data?.Role?.id === constants.ROLE.STUDENT) {
      setSession(token, "STUDENT");
    } else {
      setSession(null)
    }
    dispatch({
      type: "STUDENTSIGNIN",
      payload: {
        data,
      },
    });
    // dispatchState(login({ user: data}));
  };

  const signOut = () => {
    setSession(null);
    dispatch({type: "SIGNOUT"});
    // dispatchState(logout());
  };

  const studentSignOut = () => {
    setSession(null, "STUDENT");
    dispatch({type: "SIGNOUT"});
    // dispatchState(logout());
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const studentToken = window.localStorage.getItem("studentToken");
        if (isStartWithParent) {
          if (accessToken && isValidToken(accessToken)) {
            setSession(accessToken, "PARENT");
            const response = await getProfileApi();
            const user = response?.data?.data;
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                isInitialized: true,
                user,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isInitialized: true,
                isAuthenticated: false,
                user: null,
              },
            });
            localStorage.removeItem("accessToken");
          }
        } else if (isStartWithStudent) {
          if (studentToken && isValidToken(studentToken)) {
            setSession(studentToken, "STUDENT");
            const response = await getStudentProfileApi();
            const student = response?.data?.data;
            dispatch({
              type: "INITIALIZE",
              payload: {
                isInitialized: true,
                isStudentAuthenticated: true,
                student,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isInitialized: true,
                isStudentAuthenticated: false,
                student: null,
              },
            });
            localStorage.removeItem("studentToken");
          }
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            isStudentAuthenticated: false,
            user: null,
            student: null,
          },
        });
      }
    };

    initialize();
  }, [isStartWithParent, isStartWithStudent]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        signUp,
        signIn,
        signInAsStudent,
        studentSignIn,
        studentSignOut,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
