import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation().pathname;
  const isParent = location.startsWith("/student");

  const menuItems = [
    {
      name: "Dashboard",
      icon: require("assets/images/student/dashboard.png"),
      link: "/student/dashboard",
      identifer: "dashboard",
    },
    {
      name: "Leaderboard",
      icon: require("assets/images/student/leader-board.png"),
      link: "/student/leaderboard",
      identifer: "leaderboard",
    },
    {
      name: "Support",
      icon: require("assets/images/student/support.png"),
      link: "/student/support",
      identifer: "support",
    },
    {
      name: "Notifications",
      icon: require("assets/images/student/notify.png"),
      link: "/student/notifications",
      identifer: "notifications",
    },
    // {
    //   name: "Account Profile",
    //   icon: require("assets/images/student/account.png"),
    //   link: "/student/account-profile",
    //   identifer: "account-profile",
    // },
    {
      name: "Progress Report",
      icon: require("assets/images/student/progress.png"),
      link: "/student/progress-report",
      identifer: "progress-report",
    },
    {
      name: "Knowledge Base",
      icon: require("assets/images/student/faq.png"),
      link: "/student/knowledge-base",
      identifer: "knowledge-base",
    },
    {
      name: "Logout",
      icon: require("assets/images/student/logout.png"),
      link: "/student/logout",
      identifer: "logout",
    },
  ];

  return (
    <>
      {menuItems && menuItems?.length >= 0 && menuItems?.map((item, index) => (
        <li key={index + 1}
          className={`${isParent && location.includes(item?.identifer) ? "active" : ""}`}
        >
          <Link
            to={item?.link}
            className={`${isParent && location.includes(item?.identifer) ? "mm-active" : ""}`}
          >
            <img src={item.icon} className="m-icon" alt="icon" width={18} />
            <span> {item.name} </span>
          </Link>
        </li>
      ))}
    </>
  );
};

export default Navigation;