import { postMethod } from "../apiConfig";
import { API_URL } from "../../config/config";
import * as url from "../../helpers/student_url_helper";

export const studentSignInApi = (data) => {
  return postMethod(`${API_URL}${url.STUDENT_SIGN_IN}`, data);
};

export const signInAsStudentApi = (data) => {
  return postMethod(`${API_URL}${url.SIGN_IN_AS_STUDENT}`, data);
};

export const getStudentsApi = (data) => {
  return postMethod(`${API_URL}${url.GET_STUDENTS_BY_PARENT_EMAIL}`, data);
};