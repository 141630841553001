import axios from "axios";

const apiKey = "AIzaSyC9-SYSrAN4N8zFs5BF3HCetpyVuWxPwfk";
const getGooglePersonalDataApi = async (token) => {
    const response = await axios.get(`https://people.googleapis.com/v1/people/me?personFields=birthdays,genders,phoneNumbers&key=${apiKey}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export default getGooglePersonalDataApi;