import { postMethod } from "../apiConfig";
import { API_URL } from "../../config/config";
import * as url from "../../helpers/parent_url_helper";

export const getNotificationsApi = (data) => {
    return postMethod(`${API_URL}${url.GET_NOTIFICATIONS}`, data);
}

export const getUnreadNotificationsApi = (data) => {
    return postMethod(`${API_URL}${url.GET_UNREAD_NOTIFICATIONS}`, data);                        
}

export const updateNotificationApi = (data) => {
    return postMethod(`${API_URL}${url.UPDATE_NOTIFICATION}`, data);
}