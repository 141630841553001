import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { selectHelpVideo, setActiveHelpVideo } from "store/reducers/help-video";
import { useSelector, useDispatch } from "store";

const VideoModel = () => {
    const playerRef = useRef(null);
    const dispatch = useDispatch();
    const { activeVideoUrl, activePosterUrl, activeVideoTitle } = useSelector(selectHelpVideo);

    const handleOnClose = () => {
        playerRef.current.seekTo(0);
        dispatch(setActiveHelpVideo({ videoUrl: null, posterUrl: null, title: null }));
    };

  return (
    <div className="modal " id="videoModal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="close-btn">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="close-button"
                onClick={()=> handleOnClose()}
              />
            </div>
          </div>
          <div className="modal-body">
            <div className="video-wrapper">
              <div className="video-title">
                <h5>{activeVideoTitle}</h5>
              </div>
                <ReactPlayer
                    ref={playerRef}
                    className="react-player"
                    url={activeVideoUrl}
                    width="100%"
                    height="100%"
                    controls={true}
                    pip={true}
                    config={{
                    file: {
                        attributes: {
                        poster: activePosterUrl,
                        controlsList: "nodownload",
                        },
                    },
                    }}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModel;
