import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeVideoUrl: null,
    activePosterUrl: null,
    activeVideoTitle: null,
    loading: false,
    error: null,
};

const helpVideo = createSlice({
    name: "help-video",
    initialState,
    reducers: {
        setActiveHelpVideo(state, action) {
            state.activeVideoUrl = action.payload.videoUrl;
            state.activePosterUrl = action.payload.posterUrl;
            state.activeVideoTitle = action.payload.title;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
    },
});

export default helpVideo.reducer;

export const { setActiveHelpVideo, setLoading, setError } =
    helpVideo.actions;

export const selectHelpVideo = (state) => state.video;

