// import { useEffect } from 'react';

import DrawerContent from './DrawerContent';

const Drawer = () => {
    return (
        <>
            <div className="app-sidebar sidebar-shadow">
                <div className="scrollbar-sidebar">
                    <div className="app-sidebar__inner">
                        <ul className="vertical-nav-menu mt-5">
                            <DrawerContent />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
        
export default Drawer;