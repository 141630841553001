import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSidebarOpen: false,
    isMobile: false,
    isMobileDrawerOpen: false,
};

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setSidebarToggle: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        setMobileSidebarToggle: (state) => {
            state.isMobileDrawerOpen = !state.isMobileDrawerOpen;
        },
        setMobileView: (state, action) => {
            state.isMobile = action.payload;
        },
        setSidebarValue: (state, action) => {
            state.isSidebarOpen = action.payload;
        },
        setMobileSidebarValue: (state, action) => {
            state.isMobileDrawerOpen = action.payload;
        },
    },
});

export const { setSidebarToggle, setMobileSidebarToggle, setMobileView, setSidebarValue, setMobileSidebarValue } = layoutSlice.actions;

export default layoutSlice.reducer;

