import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuthentication from "../../../../hooks/useAuthentication";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

import constants from "../../../../constants";
import getGooglePersonalDataApi from "services/peopleapis/personalApi";
import phone from "phone";
import { fetchParentProfile } from "store/reducers/user";
import { fetchSwitchStudent } from "store/reducers/student";
import { dispatch } from "store";
import { useSnackbar } from "notistack";
import Loader from "../../../loader/Loader";

const ParentSignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [isGoogleButtonLoading, setIsGoogleButtonLoading] = useState(false);
  const [isFacebookButtonLoading, setIsFacebookButtonLoading] = useState(false);
  const [isAppleButtonLoading, setIsAppleButtonLoading] = useState(false);
  const { signIn, signUp } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();

  const emailFormat = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      role: "PARENT",
      email: "",
      password: "",
      country_phone_code: "91",
      phone_no: "",
      date_of_birth: "",
      gender: "",
      login_type: constants.LOGIN_TYPES.NOR,
      google_id: "",
      facebook_id: "",
      apple_id: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .matches(emailFormat, "Please enter valid email")
        .required("Enter Email"),
      password: Yup.string().test(
        "password",
        "Enter Password ",
        (value) => {
          if (formik.values.login_type === constants.LOGIN_TYPES.NOR) {
            return value !== "" && value !== null && value !== undefined;
          }
          return true;
        }
      ),
      google_id: Yup.string().test(
        "google_id",
        "Google ID is required",
        (value) => {
          if (formik.values.login_type === constants.LOGIN_TYPES.GGL) {
            return value !== "" && value !== null && value !== undefined;
          }
          return true;
        }
      ),
      facebook_id: Yup.string().test(
        "facebook_id",
        "Facebook ID is required",
        (value) => {
          if (formik.values.login_type === constants.LOGIN_TYPES.FCB) {
            return value !== "" && value !== null && value !== undefined;
          }
          return true;
        }
      ),
      apple_id: Yup.string().test(
        "apple_id",
        "Apple ID is required",
        (value) => {
          if (formik.values.login_type === constants.LOGIN_TYPES.APL) {
            return value !== "" && value !== null && value !== undefined;
          }
          return true;
        }
      ),
    }),
    onSubmit: async (values) => {
      if (values.login_type === constants.LOGIN_TYPES.GGL) {
        setIsGoogleButtonLoading(true);
      } else if (values.login_type === constants.LOGIN_TYPES.FCB) {
        setIsFacebookButtonLoading(true);
      } else if (values.login_type === constants.LOGIN_TYPES.APL) {
        setIsAppleButtonLoading(true);
      } else {
        setIsSubmitButtonLoading(true);
      }

      await signIn(values)
        .then((res) => {
          enqueueSnackbar("Login Successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 2000,
          });
          dispatch(fetchParentProfile());
          dispatch(fetchSwitchStudent());
          handleAllButtonLoading();
        })
        .catch(async (err) => {
          console.log(err);
          if (
            err?.response?.data?.redirectTo === "Register" &&
            values.login_type !== constants.LOGIN_TYPES.NOR
          ) {
            await signUp(values, "PARENT")
              .then((res) => {
                enqueueSnackbar("Register Successfully", {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 2000,
                });
                dispatch(fetchParentProfile());
                dispatch(fetchSwitchStudent());
                handleAllButtonLoading();
              })
              .catch((err) => {
                enqueueSnackbar(err?.response?.data?.message, {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 2000,
                });
                handleAllButtonLoading();
              });
          } else {
            enqueueSnackbar(err?.response?.data?.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              autoHideDuration: 2000,
            });
            handleAllButtonLoading();
          }
        });
    },
  });

  const handleGoogleSignIn = async () => {
    setIsGoogleButtonLoading(true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    provider.addScope("https://www.googleapis.com/auth/user.birthday.read");
    provider.addScope("https://www.googleapis.com/auth/user.gender.read");
    provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    try {
      const response = await signInWithPopup(auth, provider);
      const googlePersonalData = await getGooglePersonalDataApi(
        response?._tokenResponse?.oauthAccessToken
      );
      const [firstBirthday = {}] = googlePersonalData?.birthdays || [];
      const { date: birthday } = firstBirthday;
      const { formattedValue: gender } = googlePersonalData?.genders?.[0] || {};
      const { canonicalForm: phone_no } =
        googlePersonalData?.phoneNumbers?.[0] || {};
      const paddedMonth = birthday?.month?.toString().padStart(2, "0");
      const combinedBirthday = `${birthday?.year ?? ""}-${paddedMonth ?? ""}-${
        birthday?.day ?? ""
      }`;
      const parsedPhoneNumber = phone(phone_no);
      const withoutCountryPhoneNo = phone_no?.replace(
        `${parsedPhoneNumber?.countryCode}`,
        ""
      );
      formik.setValues({
        ...formik.values,
        first_name: response?.user?.displayName?.split(" ")[0],
        last_name: response?.user?.displayName?.split(" ")[1],
        login_type: constants.LOGIN_TYPES.GGL,
        email: response?.user?.email,
        google_id: response?.user?.uid,
        date_of_birth: combinedBirthday,
        gender: gender,
        country_phone_code: parsedPhoneNumber?.countryCode,
        phone_no:
          withoutCountryPhoneNo === null || withoutCountryPhoneNo === undefined
            ? ""
            : withoutCountryPhoneNo,
      });

      setTimeout(() => {
        formik.handleSubmit();
        setIsGoogleButtonLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFacebookSignIn = () => {
    setIsFacebookButtonLoading(true);
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        setIsFacebookButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAppleSignIn = () => {
    setIsAppleButtonLoading(true);
    const provider = new OAuthProvider("apple.com");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        setIsAppleButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (login_type) => {
    if (login_type === constants.LOGIN_TYPES.GGL) {
      handleGoogleSignIn();
    } else if (login_type === constants.LOGIN_TYPES.FCB) {
      formik.setFieldValue("facebook_id", "facebook_id");
      formik.setFieldValue("email", "hello@user.com");
      handleFacebookSignIn();
    } else if (login_type === constants.LOGIN_TYPES.FCB) {
      formik.setFieldValue("apple_id", "apple_id");
      formik.setFieldValue("email", "hello@user.com");
      handleAppleSignIn();
    }
  };

  const handleAllButtonLoading = () => {
    setIsSubmitButtonLoading(false);
    setIsGoogleButtonLoading(false);
    setIsFacebookButtonLoading(false);
    setIsAppleButtonLoading(false);
  };

  return (
    <>
      <Loader
        isLoading={
          isSubmitButtonLoading ||
          isGoogleButtonLoading ||
          isFacebookButtonLoading ||
          isAppleButtonLoading
        }
      />
      <div className="login-form">
        <form
          className="px-5"
          noValidate
          autoComplete="off"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              formik.handleSubmit();
              handleAllButtonLoading();
            }
          }}
        >
          <div className="mb-3 mt-3">
            <label htmlFor="email" className="form-label">
              Email <strong className="text-danger">*</strong>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              // placeholder="Enter email"
              name="email"
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
              {formik.errors.email && (
            <div className="text-danger">{formik.errors.email}</div>
          )}
          </div>
        

          <div className="mb-3">
            <label htmlFor="pwd" className="form-label">
              Password <strong className="text-danger">*</strong>
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="pwd"
                // placeholder="Enter password"
                name="pswd"
                onChange={(e) =>
                  formik.setFieldValue("password", e.target.value)
                }
              />
              <span className="input-group-text">
                <img
                  src={
                    !showPassword
                      ? require("assets/images/icon_eye_close.png")
                      : require("assets/images/icon_eye.png")
                  }
                  width={24}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                />
              </span>
            </div>
            {formik.errors.password && (
              <p className="text-danger">{formik.errors.password}</p>
            )}
          </div>

          <div className="mb-3 text-end">
            <Link to={"/forgot-password"} className="text-forgot">
              Forgot Password?
            </Link>
          </div>

          <div className="my-4">
            <a
              href="#"
              className="btn btn-login d-block"
              onClick={() => {
                formik.handleSubmit();
                handleAllButtonLoading();
              }}
          
            >
              {isSubmitButtonLoading ? "Loading..." : "Login as Parent"}
            </a>
          </div>

          <div className="or text-center">
            <span>Or</span>
          </div>

          <ul className="social-login d-flex flex-column">
            <li>
              <a
                href="#"
                onClick={() => handleSubmit(constants.LOGIN_TYPES.GGL)}
              >
                <img
                  src={require("assets/images/icon_google.png")}
                  alt=""
                  width=""
                  className="me-2"
                />{" "}
                <span>
                  {isGoogleButtonLoading ? "Loading..." : "Login with Google"}
                </span>
              </a>
            </li>
            {/* <li>
            <a href="#" onClick={() => handleSubmit(constants.LOGIN_TYPES.FCB)}>
                <img
                src={require("assets/images/icon_facebook.png")}
                alt=""
                width=""
                className="me-2"
                />{" "}
                <span>Login With Facebook</span>
            </a>
            </li> */}
            {/* <li>
              <a
                href="#"
                onClick={() => handleSubmit(constants.LOGIN_TYPES.APL)}
              >
                <img
                  src={require("assets/images/icon_apple.png")}
                  alt=""
                  width=""
                  className="me-2"
                />{" "}
                <span>
                  {isAppleButtonLoading ? "Loading..." : "Login with Apple ID"}
                </span>
              </a>
            </li> */}
          </ul>

          <p className="text-center extra-text mt-2 mb-4">
            {/* <Link to={"/register"}>Don't have an account?</Link> */}
            Don't have an account?
          </p>
          <Link to={"/register"} className="btn btn-signup d-block">
            Sign Up
          </Link>
        </form>
      </div>
    </>
  );
};

export default ParentSignIn;
