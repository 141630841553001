import React from "react";
import Select from "react-select";

const SelectDropdown = ({ ...other }) => {
  return (
    <div>
      <Select
        placeholder=""
        className="form-control select-dropdown"
        {...other}
        styles={{
          control: (base) => ({
            ...base,
            border: 0,
            boxShadow: "none",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "16px",
            color: "#555759",
          }),
          indicatorSeparator: (base) => ({
            ...base,
            display: "none",
          }),
        }}
      />
    </div>
  );
};

export default SelectDropdown;
