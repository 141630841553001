const firebaseConfig = {
  apiKey: "AIzaSyC9-SYSrAN4N8zFs5BF3HCetpyVuWxPwfk",
  authDomain: "colorpencil-d9d5d.firebaseapp.com",
  projectId: "colorpencil-d9d5d",
  storageBucket: "colorpencil-d9d5d.appspot.com",
  messagingSenderId: "406303872826",
  appId: "1:406303872826:web:0f32d8e9b795760d47a203",
  measurementId: "G-FCT92CDGGC"
};

export default firebaseConfig;
