import { lazy } from "react";

// project import
import StudentLayout from "layout/StudentLayout";
import ExerciseLayout from "layout/ExerciseLayout";
import Loadable from "components/Loadable";
import StudentAuthGuard from "guards/StudentAuthGuard";

const Dashboard = Loadable(lazy(() => import("pages/app-users/student/dashboard/Dashboard")));
const AccountProfile = Loadable(lazy(() => import("pages/app-users/student/account-profile/AccountProfile")));
const KnowledgeBase = Loadable(lazy(() => import("pages/app-users/student/knowledge-base/KnowledgeBase")));
const ProgressReport = Loadable(lazy(() => import("pages/app-users/student/progress-report/ProgressReport")));
const Leaderboard = Loadable(lazy(() => import("pages/app-users/student/leaderboard/Leaderboard")));
const Support = Loadable(lazy(() => import("pages/app-users/student/support/Support")));
const Settings = Loadable(lazy(() => import("pages/app-users/student/settings/Settings")));
const Notification = Loadable(lazy(() => import("pages/app-users/student/notification/Notification")));
const Quiz = Loadable(lazy(() => import("pages/app-users/student/activity/Quiz")));
const Learning = Loadable(lazy(() => import("pages/app-users/student/learning/Learning")));
// const PencilTool = Loadable(lazy(() => import("pages/app-users/student/whiteboard/PencilTool")));
const Logout = Loadable(lazy(() => import("pages/app-users/student/Auth/Logout")));

// ==============================|| Student ROUTING ||============================== //

const StudentRoutes = {
  path: "/student",
  children: [
    {
      path: "/student",
      element: (
        <StudentAuthGuard>
          <StudentLayout />
        </StudentAuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "account-profile",
          element: <AccountProfile />,
        },
        {
          path: "knowledge-base",
          element: <KnowledgeBase />,
        },
        {
          path: "progress-report",
          element: <ProgressReport />,
        },
        {
          path: "leaderboard",
          element: <Leaderboard />,
        },
        {
          path: "support",
          element: <Support />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "notifications",
          element: <Notification />
        },
        {
          path: "logout",
          element: <Logout />,
        },
      ],
    },
    {
      path: "/student",
      element: (
        <StudentAuthGuard>
          <ExerciseLayout />
        </StudentAuthGuard>
      ),
      children: [
        {
          path: "exercise/:user_task_id/:id",
          element: <Quiz />,
        },
        {
          path: "learning/:user_task_id/:id",
          element: <Learning />,
        }
      ]
    },
    // {
    //   path: "penciltool",
    //   element: <PencilTool />,
    // }
  ]
};

export default StudentRoutes;