import React, { useState } from "react";
import StudentSignIn from "./StudentSignIn";
import ParentSignIn from "./ParentSignIn";

const SignIn = () => {
  const [showParentForm, setShowParentForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const handleRadioChange = (event) => {
    if (event.target.value === "student") setShowParentForm(false);
    else setShowParentForm(true);
  };

  return (
    <>
      <div className="login d-flex">
        <div className="left-block d-flex flex-column justify-content-center bg-light-pink">
          <div className="welcome-block">
            <h1 className="m-hide">Welcome to the</h1>
            <img
              src={require("assets/images/logo.png")}
              alt="logo"
              width={238}
              className="mb-3"
            />
            <p className="m-hide welcome-txt">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
        </div>

        <div className="right-block">
          <div className="login-toggle mb-3">
            <input
              type="radio"
              id="login-toggle-parent"
              name="login"
              value="parent"
              checked={showParentForm}
              onChange={handleRadioChange}
            />
            <label className="radio-button" for="login-toggle-parent">
              Parent
            </label>

            <input
              type="radio"
              id="login-toggle-student"
              name="login"
              value="student"
              checked={!showParentForm}
              onChange={handleRadioChange}
            />
            <label className="radio-button" for="login-toggle-student">
              Student
            </label>
          </div>
          {showParentForm ? <ParentSignIn /> : <StudentSignIn />}
        </div>
      </div>
    </>
  );
};

export default SignIn;
