import { postMethod } from "../apiConfig";
import { API_URL } from "../../config/config";
import * as url from "../../helpers/student_url_helper";

export const getExcercisesApi = (data) => {
    return postMethod(`${API_URL}${url.GET_EXERCISES}`, data);
};

export const getExerciseDetailApi = (data) => {
    return postMethod(`${API_URL}${url.GET_EXERCISE_DETAIL}`, data);
}

export const getUserTaskDetailApi = (data) => {
    return postMethod(`${API_URL}${url.GET_USER_TASK_DETAIL}`, data);
}

export const updateUserTaskTimerApi = (data) => {
    return postMethod(`${API_URL}${url.UPDATE_USER_TASK_TIMER}`, data);
}

export const getQuestionsApi = (data) => {
    return postMethod(`${API_URL}${url.GET_QUESTIONS}`, data);
}

export const submitUserAnswerApi = (data) => {
    return postMethod(`${API_URL}${url.SUBMIT_USER_ANSWER}`, data);
}

export const checkIsAlreadyAnsweredApi = (data) => {
    return postMethod(`${API_URL}${url.CHECK_IS_ALREADY_ANSWERED}`, data);
}

export const submitUserTaskStatusApi = (data) => {
    return postMethod(`${API_URL}${url.SUBMIT_USER_TASK_STATUS}`, data);
}

export const updateQuizTimerApi = (data) => {
    return postMethod(`${API_URL}${url.UPDATE_QUIZ_TIMER}`, data);
}

export const getUserTaskReportApi = (data) => {
    return postMethod(`${API_URL}${url.GET_USER_TASK_REPORT}`, data);
}

export const getLearningApi = (data) => {
    return postMethod(`${API_URL}${url.GET_LEARNING}`, data);
}