import { Link } from "react-router-dom";
import { useSelector } from "store";
import { selectUser } from "store/reducers/user";

const Profile = () => {
    const parent = useSelector(selectUser);
    return (
        <div className="header-btn-lg pr-0">
            <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                        <div className="btn-group">
                            <a
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className="p-0 btn"
                            >
                                <img
                                    width={29}
                                    className="rounded-circle"
                                    src={ parent?.profile_image ? parent?.profile_image : require('assets/images/male_profile.jpg') }
                                    alt="profile-img"
                                    style={{
                                        width: "29px",
                                        height: "29px",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        marginRight: "5px",
                                      }}
                                    onError={(e)=> {
                                        e.target.onerror = null;
                                        e.target.src = require('assets/images/male_profile.jpg');
                                    }}
                                />
                                <span className="p-name i-hide">
                                    {" "} Hello, <strong>{parent?.first_name +" "+ parent?.last_name}</strong>
                                </span>
                                <img
                                    width={10}
                                    src={require('../../../../assets/images/icon_down_arrow.png')}
                                    alt="icon_down_arrow"
                                    style={{ marginLeft: "5px" }}
                                />
                            </a>
                            <div
                                tabIndex={-1}
                                role="menu"
                                aria-hidden="true"
                                className="dropdown-menu dropdown-menu-right"
                            >
                                <Link to={'/parent/account-profile'} tabIndex={0} className="dropdown-item">Profile</Link>
                                {/* <Link to={'/parent/settings'} tabIndex={0} className="dropdown-item">Settings </Link> */}
                                <div tabIndex={-1} className="dropdown-divider" />
                                <Link to={'/parent/logout'} tabIndex={0} className="dropdown-item">Logout </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;