// Common
export const GET_GRADES = "api/common/grades"
export const GET_LEVELS = "api/common/levels"
export const GET_LANGUAGES = "api/common/languages"

// Auth
export const SIGN_IN = "api/user/sign-in"
export const SIGN_UP = "api/user/sign-up"
export const FORGOT_PASSWORD = "api/user/forgot-password"
export const SEND_OTP = "api/user/send-otp"
export const CHECK_OTP = "api/user/check-otp"
export const RESET_PASSWORD = "api/user/reset-password"

// Places
export const GET_COUNTRIES = "api/common/countries"
export const GET_STATES = "api/common/states"
export const GET_CITIES = "api/common/cities"

// Users
export const GET_PROFILE_DETAILS = "api/user/get-profile-details"
export const UPDATE_PROFILE_DETAILS = "api/user/update-profile-detail"
export const UPDATE_PASSWORD = "api/user/update-password"
export const UPDATE_PROFILE_IMAGE = "api/user/update-profile-image"

// FAQs
export const GET_FAQS = "api/user/get-faqs"

// Help-Video
export const GET_HELP_VIDEOS = "api/user/get-videos"
export const GET_HELP_VIDEO = "api/user/get-video"

// Students
export const CREATE_STUDENT = "api/user/create-student"
export const UPDATE_STUDENT = "api/user/update-student"
export const DELETE_STUDENT = "api/user/delete-student"
export const UPDATE_STUDENT_SIGN_IN = "api/user/update-sign-in"
export const GET_STUDENTS = "api/user/get-students"
export const GET_SWITCH_STUDENTS = "api/user/get-switch-students"
export const GET_STUDENT = "api/user/get-student"

// User address
export const GET_USER_ADDRESSES = "api/user/get-user-addresses"
export const GET_USER_ADDRESS = "api/user/get-user-address"
export const GET_USER_ADDRESS_BY_TYPE = "api/user/get-user-address-by-type"
export const CREATE_USER_ADDRESS = "api/user/create-user-address"

// Progress Report
export const GET_STUDENT_CURRENT_WEEK_PROGRESS = "api/user/get-current-week-progress"
export const GET_STUDENT_CURRENT_WEEK_GRAPH_PROGRESS = "api/user/get-weekly-progress"

// Leaderboard
export const GET_STUDENT_LEADERBOARD = "api/user/get-student-leader-board"
export const GET_LEADERBOARD = "api/user/get-leader-board"

// Assignment
export const GET_EXERCISES = "api/student/get-exercises"
export const GET_EXERCISES_USER = "api/user/get-exercises"
export const GET_EXERCISE_DETAIL = "api/student/get-exercise"
export const GET_QUESTIONS = "api/student/get-questions"
export const SUBMIT_USER_ANSWER = "api/student/submit-answer"
export const CHECK_IS_ALREADY_ANSWERED = "api/student/check-is-already-answered"
export const SUBMIT_USER_TASK_STATUS = "api/student/submit-user-task-status"
export const UPDATE_QUIZ_TIMER = "api/student/update-quiz-timer"
export const GET_USER_TASK_REPORT = "api/student/get-usertask-report"

// Transaction
export const GET_PAYMENT_HISTORIES = "api/user/get-payment-histories"
export const GET_PAYMENT_HISTORY = "api/user/get-payment-history"

// User Task
export const GET_ASSIGNED_EXERCISES = "api/common/get-assigned-exercises"

// Support
export const GET_SUPPORTS = "api/user/get-supports"
export const CREATE_SUPPORT = "api/user/create-support"



















////////////////////////////////////////////////

export const GET_STUDENTS_BY_PARENT_EMAIL = "api/student/get-students"
export const STUDENT_SIGN_IN = "api/student/sign-in"
export const GET_STUDENT_PROFILE_DETAILS = "api/student/get-profile-details"
export const UPDATE_STUDENT_PROFILE_DETAILS = "api/student/update-profile-detail"
export const UPDATE_STUDENT_PASSWORD = "api/student/update-password"
export const UPDATE_STUDENT_PROFILE_IMAGE = "api/student/update-profile-image"