
import SectionTitle from './SectionTitle'
// import Search from './Search'
// import Notification from './Notification'
import Profile from './Profile'

const HeaderContent = () => {
    return (
        <div className="app-header__content py-3">
            <SectionTitle />
            <div className="app-header-right">
                {/* <Search /> */}
                {/* <Notification /> */}
                <Profile />
            </div>
        </div>
    );
}

export default HeaderContent;