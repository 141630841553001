import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuthentication from "../../../../hooks/useAuthentication";
import { fetchStudentProfile } from "store/reducers/user";
import { dispatch } from "store";
import { useSnackbar } from "notistack";
import SelectDropdown from "components/select-dropdown/SelectDropdown";
import { getStudentsApi } from "../../../../apis/Student/AuthApis";
import Loader from "../../../loader/Loader";

const StudentSignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const { studentSignIn } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();

  const emailFormat = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const handleEmailChange = async (event) => {
    formik.setFieldValue("email", event.target.value);
    await getStudentsApi({ email: event.target.value })
      .then((res) => {
        if (res?.data?.success) {
          setStudentList(res?.data?.data);
        }
      })
      .catch(async (err) => {
        setStudentList([]);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(emailFormat, "Please enter valid email")
        .required("Enter Email"),
      username: Yup.string().required("Enter Username"),
      password: Yup.string().required("Enter Password"),
    }),
    onSubmit: async (values) => {
      setIsSubmitButtonLoading(true);
      await studentSignIn(values)
        .then((res) => {
          setIsSubmitButtonLoading(false);
          enqueueSnackbar("Login Successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 2000,
          });
          if(res?.data?.statusCode === 422) {
              enqueueSnackbar(res?.data?.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              autoHideDuration: 2000,
            });
          }
          dispatch(fetchStudentProfile());
          window.location.href = "/student/dashboard";
        })
        .catch(async (err) => {
          setIsSubmitButtonLoading(false);
          console.log(err);
          enqueueSnackbar(err?.response?.data?.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 2000,
          });
        });
    },
  });

  return (
    <>
      {" "}
      <Loader isLoading={isSubmitButtonLoading} />
      <div className="login-form">
        <form
          className="px-5"
          noValidate
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              formik.handleSubmit();
            }
          }}
        >
          <div className="mb-3 mt-3">
            <label htmlFor="email" className="form-label">
              Parent Email <strong className="text-danger">*</strong>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              // placeholder="Enter email"
              name="email"
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
              onChange={handleEmailChange}
            />
                 {formik.errors.email && (
            <p className="text-danger">{formik.errors.email}</p>
          )}
          </div>
     

          <div className="mb-3 mt-3">
            <label for="" className="form-label">
              {" "}
              Username <strong className="text-danger">*</strong>
            </label>
            <SelectDropdown
              options={studentList?.map((value) => ({
                value: `${value.username}`,
                label: `${value.first_name}`,
              }))}
              onChange={(e) => {
                setSelectedUsername(e);
                formik.setFieldValue("username", e?.value);
              }}
              value={selectedUsername || ""}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="text-danger">{formik.errors.username}</div>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="pwd" className="form-label">
              Password <strong className="text-danger">*</strong>
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="pwd"
                // placeholder="Enter password"
                name="pswd"
                onChange={(e) =>
                  formik.setFieldValue("password", e.target.value)
                }
              />
              <span className="input-group-text">
                <img
                  src={
                    !showPassword
                      ? require("assets/images/icon_eye_close.png")
                      : require("assets/images/icon_eye.png")
                  }
                  width={24}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                />
              </span>
            </div>
            {formik.errors.password && (
              <p className="text-danger">{formik.errors.password}</p>
            )}
          </div>

          <div className="my-4">
            <a
              href="#"
              className="btn btn-login d-block"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isSubmitButtonLoading ? "Loading..." : "Login as Student"}
            </a>
          </div>
        </form>
      </div>
    </>
  );
};

export default StudentSignIn;
