import { Link } from "react-router-dom";
import HeaderContent from "./HeaderContent";
import { useSelector, useDispatch } from "store";
import { setSidebarToggle } from "store/reducers/parent-layout";

const Header = () => {
  const isSidebarOpen = useSelector((state) => state.studentLayout.isSidebarOpen);
  const isMobileView = useSelector((state) => state.studentLayout.isMobile);
  const dispatch = useDispatch();
  const toggleMenu = () => {
    dispatch(setSidebarToggle());
  };

  return (
    <div className="app-header">
      <div className="app-header__logo">
        <div className="logo">
          <Link to="/student/dashboard" className="header__logo">
            <img
              src={require("assets/images/student/logo.png")}
              alt="logo"
              width={150}
            />
          </Link>
        </div>
        <div className="header__pane ml-auto">
          <div>
            <button
              type="button"
              data-class="closed-sidebar"
              onClick={toggleMenu}
              className={`hamburger close-sidebar-btn hamburger--elastic 
              ${
                isMobileView && !isSidebarOpen ? "is-active" : ""
              } ${!isMobileView && isSidebarOpen ? "is-active" : ""}`}
              // className={`hamburger close-sidebar-btn hamburger--elastic`}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button
            type="button"
            className="hamburger hamburger--elastic mobile-toggle-nav"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </div>

      <HeaderContent />
    </div>
  );
};

export default Header;
