import jwtDecode from "jwt-decode";
import axios from "axios";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken, type) => {
  if (accessToken) {
    if (type === "STUDENT") {
      localStorage.setItem("studentToken", accessToken);
    } else {
      localStorage.setItem("accessToken", accessToken);
    }
  } else {
    if (type === "STUDENT") {
      localStorage.removeItem("studentToken");
    } else {
      localStorage.removeItem("accessToken");
    }
    delete axios.defaults.headers.common.Authorization;
  }
};

const getAccessToken = () => localStorage.getItem("accessToken");
const getStudentToken = () => localStorage.getItem("studentToken");

export { isValidToken, setSession, getAccessToken, getStudentToken };
