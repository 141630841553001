// Auth
export const GET_STUDENTS_BY_PARENT_EMAIL = "api/student/get-students"
export const STUDENT_SIGN_IN = "api/student/sign-in-v2"
export const SIGN_IN_AS_STUDENT = "api/user/student-sign-in"

export const GET_DASHBOARD_COUNT = "api/student/get-dashboard-counts"

// Assignment
export const GET_EXERCISES = "api/student/get-exercises"
export const GET_EXERCISES_USER = "api/user/get-exercises"
export const GET_EXERCISE_DETAIL = "api/student/get-exercise"
export const GET_USER_TASK_DETAIL = "api/student/get-user-task-detail"
export const UPDATE_USER_TASK_TIMER = "api/student/update-user-task-timer"
export const GET_QUESTIONS = "api/student/get-questions"
export const SUBMIT_USER_ANSWER = "api/student/submit-answer"
export const CHECK_IS_ALREADY_ANSWERED = "api/student/check-is-already-answered"
export const SUBMIT_USER_TASK_STATUS = "api/student/submit-user-task-status"
export const UPDATE_QUIZ_TIMER = "api/student/update-quiz-timer"
export const GET_USER_TASK_REPORT = "api/student/get-usertask-report"
export const GET_LEARNING = "api/student/get-learning"

// Student
export const GET_STUDENT_PROFILE_DETAILS = "api/student/get-profile-details"
export const UPDATE_STUDENT_PROFILE_DETAILS = "api/student/update-profile-detail"
export const UPDATE_STUDENT_PASSWORD = "api/student/update-password"
export const UPDATE_STUDENT_PROFILE_IMAGE = "api/student/update-profile-image"

// Knowledgebase
export const GET_FAQS = "api/student/get-faqs"
export const GET_HELP_VIDEOS = "api/student/get-videos"
export const GET_HELP_VIDEO = "api/student/get-video"                   

// Support
export const GET_SUPPORTS = "api/student/get-supports"
export const CREATE_SUPPORT = "api/student/create-support"

// Leaderboard
export const GET_LEADERBOARD = "api/student/get-leader-board"

// Notifications
export const GET_NOTIFICATIONS = "api/student/get-notifications"
export const GET_UNREAD_NOTIFICATIONS = "api/student/get-unread-notifications"
export const UPDATE_NOTIFICATION = "api/student/update-notification"

// Progress Report
export const GET_PROGRESS_REPORT = "api/student/get-progress-report"